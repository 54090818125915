import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122')
];

export const server_loads = [32,38,39];

export const dictionary = {
		"/(app)/(user)": [65,[2,24],[3]],
		"/(app)/(user)/admin": [~81,[2,24,32],[3]],
		"/(app)/(user)/admin/ai-prompts": [82,[2,24,32],[3]],
		"/(app)/(user)/admin/ai-prompts/conversation-summary": [83,[2,24,32],[3]],
		"/(app)/(user)/admin/ai-prompts/sentiment-analysis": [84,[2,24,32],[3]],
		"/(app)/(user)/admin/ai-prompts/topic-generation": [85,[2,24,32],[3]],
		"/(app)/(user)/admin/data-migration": [86,[2,24,32],[3]],
		"/(app)/(user)/admin/experiments": [87,[2,24,32],[3]],
		"/(app)/(user)/admin/showcase-management": [88,[2,24,32],[3]],
		"/(app)/(user)/admin/systems-monitoring": [89,[2,24,32],[3]],
		"/(app)/(user)/admin/systems-notices": [90,[2,24,32],[3]],
		"/(app)/(user)/admin/users-teams": [91,[2,24,32],[3]],
		"/(app)/(user)/admin/users-teams/nsac": [92,[2,24,32],[3]],
		"/(app)/(user)/admin/users-teams/teams": [93,[2,24,32],[3]],
		"/(app)/(user)/admin/users-teams/users": [~94,[2,24,32],[3]],
		"/(app)/(studio)/channel/[channelId]": [43,[2,5,6],[3]],
		"/(app)/(studio)/channel/[channelId]/insights": [49,[2,5,6,10],[3]],
		"/(app)/(studio)/channel/[channelId]/(private)/setup": [44,[2,5,6,7,8],[3]],
		"/(app)/(studio)/channel/[channelId]/(private)/setup/behavior": [45,[2,5,6,7,8],[3]],
		"/(app)/(studio)/channel/[channelId]/(private)/setup/reactions": [46,[2,5,6,7,8],[3]],
		"/(app)/(studio)/channel/[channelId]/(private)/setup/settings": [47,[2,5,6,7,8],[3]],
		"/(app)/(studio)/channel/[channelId]/(private)/share": [48,[2,5,6,7,9],[3]],
		"/dev/db": [102,[34,35]],
		"/dev/db/teams": [103,[34,35]],
		"/dev/db/users": [104,[34,35]],
		"/dev/e2e": [105,[34]],
		"/dev/exports/sheetjs": [106,[34]],
		"/dev/generic-query/[vid]-get-comments": [107,[34]],
		"/dev/player-layout": [108,[34]],
		"/dev/routes": [109,[34]],
		"/dev/sentry": [110,[34]],
		"/dev/test-errors": [111,[34,36],[,37]],
		"/dev/test-errors/api-errors": [112,[34,36],[,37]],
		"/dev/test-errors/client-error": [113,[34,36],[,37]],
		"/dev/test-errors/client-unhandled-error": [114,[34,36],[,37]],
		"/dev/test-errors/render-error": [115,[34,36],[,37]],
		"/dev/test-errors/server-error": [~116,[34,36],[,37]],
		"/dev/test-errors/server-unhandled-error": [~117,[34,36],[,37]],
		"/dev/test-errors/video-error": [118,[34,36],[,37]],
		"/dev/[videoId]/detectScenes": [99,[34]],
		"/dev/[videoId]/keymoments": [100,[34]],
		"/dev/[videoId]/thumbnail-at-time": [101,[34]],
		"/(app)/(user)/featurebase/sso": [~95,[2,24],[3]],
		"/(app)/(auth)/login": [41,[2,4],[3]],
		"/(app)/logout": [97,[2],[3]],
		"/(app)/(user)/onboarding": [96,[2,24,33],[3]],
		"/(app)/(auth)/signup": [42,[2,4],[3]],
		"/(app)/(studio)/video/[videoId=videoId]/(insights)": [50,[2,5,11,12],[3]],
		"/(app)/(studio)/video/[videoId=videoId]/(insights)/questions": [51,[2,5,11,12,13],[3]],
		"/(app)/(studio)/video/[videoId=videoId]/(insights)/questions/[featureId]/(summary)": [52,[2,5,11,12,13,14],[3]],
		"/(app)/(studio)/video/[videoId=videoId]/(insights)/questions/[featureId]/responses": [53,[2,5,11,12,13,14,15],[3]],
		"/(app)/(studio)/video/[videoId=videoId]/(insights)/questions/[featureId]/responses/[responseId]": [54,[2,5,11,12,13,14,15],[3]],
		"/(app)/(studio)/video/[videoId=videoId]/(insights)/reactions": [55,[2,5,11,12,16],[3]],
		"/(app)/(studio)/video/[videoId=videoId]/(insights)/reactions/(persistent-comments)/responses": [56,[2,5,11,12,16,17],[3]],
		"/(app)/(studio)/video/[videoId=videoId]/(insights)/reactions/(persistent-comments)/responses/[commentId]": [57,[2,5,11,12,16,17],[3]],
		"/(app)/(studio)/video/[videoId=videoId]/(private)/setup": [58,[2,5,11,18,19],[3]],
		"/(app)/(studio)/video/[videoId=videoId]/(private)/setup/custom-fields": [59,[2,5,11,18,19,20],[3]],
		"/(app)/(studio)/video/[videoId=videoId]/(private)/setup/questions": [60,[2,5,11,18,19,21],[3]],
		"/(app)/(studio)/video/[videoId=videoId]/(private)/setup/questions/[featureId]": [61,[2,5,11,18,19,21,22],[3]],
		"/(app)/(studio)/video/[videoId=videoId]/(private)/setup/reactions": [62,[2,5,11,18,19],[3]],
		"/(app)/(studio)/video/[videoId=videoId]/(private)/setup/settings": [63,[2,5,11,18,19],[3]],
		"/(app)/(studio)/video/[videoId=videoId]/(private)/share": [64,[2,5,11,18,23],[3]],
		"/v": [~119,[38]],
		"/v/[videoId=videoId]": [~122,[38]],
		"/v/[channelId=channelId]": [120,[38,39]],
		"/v/[channelId=channelId]/[videoId=videoId]": [~121,[38,39,40]],
		"/(app)/(user)/[teamId]/(workspace)": [66,[2,24,25,26],[3]],
		"/(app)/(user)/[teamId]/(workspace)/home": [~68,[2,24,25,26],[3]],
		"/(app)/(user)/[teamId]/settings": [71,[2,24,25,30],[3]],
		"/(app)/(user)/[teamId]/settings/account": [74,[2,24,25,30],[3]],
		"/(app)/(user)/[teamId]/settings/account/api": [75,[2,24,25,30],[3]],
		"/(app)/(user)/[teamId]/settings/account/notifications": [76,[2,24,25,30],[3]],
		"/(app)/(user)/[teamId]/settings/account/preferences": [77,[2,24,25,30],[3]],
		"/(app)/(user)/[teamId]/settings/(admin-settings)/billing": [72,[2,24,25,30,31],[3]],
		"/(app)/(user)/[teamId]/settings/(admin-settings)/import-export": [73,[2,24,25,30,31],[3]],
		"/(app)/(user)/[teamId]/settings/members": [78,[2,24,25,30],[3]],
		"/(app)/(user)/[teamId]/settings/usage": [79,[2,24,25,30],[3]],
		"/(app)/(user)/[teamId]/settings/video": [80,[2,24,25,30],[3]],
		"/(app)/(user)/[teamId]/(workspace)/trash": [69,[2,24,25,26,28],[3]],
		"/(app)/(user)/[teamId]/(workspace)/[folderId=folderId]": [67,[2,24,25,26,27],[3]],
		"/(app)/(user)/[teamId]/[...videoPage]": [70,[2,29],[3]],
		"/[...other]": [98]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';